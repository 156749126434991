import { initCustomCursor } from './common';

export default function tour_single() {

  $( '.tour-info .gallery-carousel' ).imagesLoaded(function(){
    var gallery_w = 0;
    $( '.tour-info .gallery-carousel .item img' ).each(function(){
      gallery_w += $(this).width();
    });

    if (gallery_w > $(window).width()) {
      $( '.tour-info .gallery-carousel' ).addClass('owl-carousel');		
      $( '.tour-info .gallery-carousel' ).owlCarousel( {
        items: 1,
        margin: 0,
        dotsEach: true,
        loop: false,
        nav: true,
        smartSpeed: 1000,
        mouseDrag: true,
        touchDrag: true,
        autoWidth: false,
        navText: ['<i class="fal fa-angle-left"></i>', '<i class="fal fa-angle-right"></i>'],
        responsive: {
          768: {
            items: 3,
            autoWidth: true,
          }
        }
      } );

      // initCustomCursor();
    } else {
      $( '.tour-info .gallery-carousel .item' ).removeClass('custom-cursor');
      $( '.tour-info .gallery-carousel .item .cursor' ).remove();
    }
  });

  // $('.concert-reviews .review-list').owlCarousel({
  //   items: 1,
  //   margin: 20,
  //   dotsEach: true,
  //   loop: true,
  //   nav: true,
  //   smartSpeed: 1000,
  //   mouseDrag: true,
  //   touchDrag: true,
  //   navText: ['<i class="fal fa-angle-left"></i>','<i class="fal fa-angle-right"></i>'],
  // });

  var loop = $('.setlist-lyrics-carousel .item').length > 4 ? true : false;

  $('.setlist-lyrics-carousel').owlCarousel({
    items: 1.3,
    margin: 20,
    dotsEach: true,
    loop: loop,
    nav: true,
    smartSpeed: 1000,
    mouseDrag: true,
    touchDrag: true,
    navText: ['<i class="fal fa-arrow-left"></i>','<i class="fal fa-arrow-right"></i>'],
    responsive: {
      768: {
        items: 3.3,
      },
      992: {
        items: 4.3,
      }
    }
  });
	

	$('.tour-videos .videos-list').owlCarousel({
    items: 1.2,
    margin: 20,
    dotsEach: true,
    loop: true,
    nav: true,
    smartSpeed: 1000,
    mouseDrag: true,
    touchDrag: true,
    navText: ['<i class="fal fa-arrow-left"></i>','<i class="fal fa-arrow-right"></i>'],
    responsive: {
      768: {
        items: 2.15,
        margin: 60
      }
    }
  });

  $('.tour-videos .videos-list a').magnificPopup({
    type: 'iframe',
    mainClass: 'mfp-fade',
    removalDelay: 160,
    preloader: false,
    fixedContentPos: false,
    closeOnBgClick: false
  });

  initCustomCursor();
}

