export default function music_song_player() {
  var current_playing_id = 0;

  $(document).on('click', '.lsapb-play', function(e){
    e.preventDefault();

    $('.lsapb-play').not(this).each(function(){
      $(this).removeClass('playing');
      $(this).find('i').addClass('fa-play');
      $(this).find('i').removeClass('fa-pause');
    });

    if (!$(this).hasClass('playing')) {
      $('.stream-popup-wrapper').remove();
      $('body').append('<div class="stream-popup-wrapper"></div>');
      $('.stream-popup-wrapper').append($('.legacy-streaming-audio-player .lsap-stream-popup').first().clone());
      $('.stream-popup-wrapper button[data-service=apple_music]').html('<i class="fab fa-apple"></i>');
      $('.stream-popup-wrapper button[data-service=spotify]').html('<i class="fab fa-spotify"></i>');
      $('.stream-popup-wrapper button[data-service=deezer]').html('<i class="fab fa-deezer"></i>');
    }

    $(this).addClass('playing');
    $(this).find('i').toggleClass('fa-play');
    $(this).find('i').toggleClass('fa-pause');

    
    var apple_music_pre_url = $(this).parent().parent().data('apple_music_pre_url');
    var spotify_pre_url = $(this).parent().parent().data('spotify_pre_url');
    var deezer_pre_url = $(this).parent().parent().data('deezer_pre_url');

    if (apple_music_pre_url == '') {
      $('.stream-popup-wrapper button[data-service=apple_music]').parent().hide();
    }

    if (spotify_pre_url == '') {
      $('.stream-popup-wrapper button[data-service=spotify]').parent().hide();
    }

    if (deezer_pre_url == '') {
      $('.stream-popup-wrapper button[data-service=deezer]').parent().hide();
    }

    var track_id = $(this).parent().parent().data('track-id');

    $('.lsap-item[data-track-id=' + track_id + '] .lsap-track-controls-play-pause').click();

    if ($(this).find('i').hasClass('fa-pause')) {
      current_playing_id = track_id;
      setTimeout(function(){
        updateProgressBar(track_id);
      }, 50);

      var top = $(this).offset().top - 10;
      var left = $(this).offset().left - 15;
			var popup = $('.stream-popup-wrapper .lsap-stream-popup').css('opacity', '1');
      var view_w = $(window).width();

      if (left + popup.outerWidth() > view_w) {
        popup.css('--arrow-offset', (left + popup.outerWidth() - view_w + 15) + 'px');
        left = view_w - popup.outerWidth() - 15;        
      }

      popup.css('top', top - popup.outerHeight());
      popup.css('left', left).fadeIn();
      setTimeout( function () {
        popup.fadeOut();
      }, 5000);
    } else {
      current_playing_id = 0;
    }

    // Track Videos
    $('.track-videos').hide();
    $('#track-videos-' + track_id).show();
    $('#track-videos-' + track_id + ' .track-videos-desktop').css('margin-top', $('tr[data-track-id=' + track_id + ']').position().top + $('tr[data-track-id=' + track_id + ']').height() / 2);
  });

  function updateProgressBar(track_id) {
    if (current_playing_id == 0 || current_playing_id != track_id) return;

    var lsap_progressbar = $('.lsap-item[data-track-id=' + track_id + '] .lsap-track-progress');
    var progress = lsap_progressbar.width() / lsap_progressbar.parent().outerWidth() * 100;
    $('#lsapb-play-' + track_id).css('--progress', progress + '%');

    if (progress < 100) {
      setTimeout(function(){
        updateProgressBar(track_id);
      }, 50);
    } else {
      $('#lsapb-play-' + track_id + ' i').addClass('fa-play');
      $('#lsapb-play-' + track_id + ' i').removeClass('fa-pause');
      $('#lsapb-play-' + track_id).removeClass('playing');
      lsap_progressbar.width(0);
    }
  }

  $(document).on('click', '.stream-popup-wrapper .lsap-stream-service-selection ul li button', function(e){
    e.preventDefault();
    $('.stream-popup-wrapper .stream-service-selection ul li button').removeClass('active');
    $(this).addClass('active');
    $('.legacy-streaming-audio-player .lsap-stream-service-selection button[data-service=' + $(this).data('service') + ']').click();
  });

  // $(document).on('click', '.lsapb-save', function(e){
  //   e.preventDefault();
  //   var track_id = $(this).parent().parent().data('track-id');
  //   $('.lsap-item[data-track-id=' + track_id + '] .lsap-track-actions .lsap-library-add').click();
  // });

  $(document).on('click', '.lsapb-save', function(e){
    e.preventDefault();

    var track_id = $(this).parent().parent().data('track-id');

    $('.lsap-save-popup').remove();
    $('body').append('<div class="lsap-save-popup"></div>');
    $('.lsap-save-popup').html($('#save-popup-' + track_id).html());
    $('.lsap-save-popup').data('track-id', track_id);

    var left = $(this).offset().left;
    var top = $(this).offset().top + $(this).height() + 10;

    if (left + $('.lsap-save-popup').outerWidth() > $(window).width()) {
      left = $(window).width() - $('.lsap-save-popup').outerWidth() - 10;
    }

    $('.lsap-save-popup').css('left', left);
    $('.lsap-save-popup').css('top', top);
  });

  $(document).on('click', '.lsap-save-popup .save-track a', function(e){
    e.preventDefault();

    var track_id = $('.lsap-save-popup').data('track-id');
    save_ele_clicked = $('.lsap-item[data-track-id=' + track_id + '] .lsap-track-actions .lsap-library-add');
    $('#save-popup .save-btns a[data-service=' + $(this).data('service') + ']').click();
    $('.lsap-save-popup').remove();
  });

  $(document).on('click', '.lsap-save-popup .save-album a', function(e){
    e.preventDefault();

    var track_id = $('.lsap-save-popup').data('track-id');
    save_ele_clicked = $('#save-popup-' + track_id + ' .lsap-album-save');
    $('#save-popup .save-btns a[data-service=' + $(this).data('service') + ']').click();
    $('.lsap-save-popup').remove();
  });

  $(document).click(function(e){
    var target = $(e.target);
    if (!target.hasClass('lsapb-save') && target.parents('.lsapb-save, .lsap-save-popup').length == 0) {
      $('.lsap-save-popup').remove();
    }
  });

  $('.link-lsap-popup').click(function(e){
    e.preventDefault();
    var href = $( this ).attr( 'href' );
    var h = 475;
    var w = 500;
    var y = window.top.outerHeight / 2 + window.top.screenY - (
      h / 2
    );
    var x = window.top.outerWidth / 2 + window.top.screenX - (
      w / 2
    );
    return window.open( href, 'Legacy Streaming Audio Player', `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}` );
  });

  $('.track-videos .item a').magnificPopup({
    type: 'iframe',
    mainClass: 'mfp-fade',
    removalDelay: 160,
    preloader: false,
    fixedContentPos: false,
    closeOnBgClick: false,
    callbacks: {
      open: function(){
        $('.player-tracks .fa-pause').parent().click();
      }
    }
  });

  $('.track-videos-mobile').owlCarousel({
    items: 1.2,
    margin: 20,
    dotsEach: true,
    loop: true,
    nav: true,
    smartSpeed: 1000,
    mouseDrag: true,
    touchDrag: true,
    navText: ['<i class="fal fa-arrow-left"></i>','<i class="fal fa-arrow-right"></i>'],
    responsive: {
      768: {
        items: 2.15,
        margin: 60
      }
    }
  });

}