export default function news_single() {
  $('.news-content').before($('.news-content__content .is-type-video'));
  $('.news-content__content .is-type-video').remove();

  $('.wp-block-gallery').each(function(){
    var instance = this; 
    $(this).magnificPopup({
      delegate: 'a',
      type: 'image',
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        preload: [0, 2],
        arrowMarkup: '<button class="btn-%dir% far fa-arrow-%dir%"></button>',
      },
      image: {
        markup: '<div class="mfp-figure">'+
          '<div class="mfp-close"></div>'+
          '<div class="mfp-img-wrapper"><div class="mfp-img"></div>'+
          '<div class="mfp-arrows"><a class="btn-prev" href="#"><i class="far fa-arrow-left"></i></a><a class="btn-next" href="#"><i class="far fa-arrow-right"></i></a></div></div>'+
        '</div>',
        verticalFit: true,
      },
    });

    $(document).on('click', '.mfp-arrows .btn-prev', function(e){
      e.preventDefault();
      
      $(instance).magnificPopup('prev');
    });

    $(document).on('click', '.mfp-arrows .btn-next', function(e){
      e.preventDefault();
      
      $(instance).magnificPopup('next');
    });
  });
  
}