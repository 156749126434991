export default function music_common() {
  $('.credits-group .item a').hover(function(){
    var mc = $('#' + $(this).data('mc'));
    var mc_img = mc.find('.credits-group__name img');
    var mc_img_container = mc.find('.credits-group__name');
    mc_img.attr('src', $(this).data('image'));
    mc_img.width(mc_img_container.width() * 0.8);
    mc_img.css('top', $(window).height() / 2);
    mc_img.css('left', mc_img_container.offset().left + mc_img_container.width() / 2);
    mc_img.show();
  }, function(){
    var mc = $('#' + $(this).data('mc'));
    var mc_img = mc.find('.credits-group__name img');
    mc_img.hide();
  });
}