export default function music_song_index() {
  $('.search-anchor').click(function(e){
    e.preventDefault();

    if ( $('#search-query').val() != '' ) {
      filter_songs();
    } else {
      $('.page-title').toggleClass('search-open');
      $('.page-container').toggleClass('page-search-open');
    }
  });

  $('#search-query').keypress(function (e) {
    if ( e.keyCode == 13 ) {
      filter_songs();
    }
  });

  $('#search-query').focus(function(){
    $('.page-container').addClass('page-search-open');
  });

  $('.filters-bar .filter-anchor').click(function(e){
    e.preventDefault();
  });

  $('.filters-bar .view-mode').click(function(e){
    e.preventDefault();
    $(this).toggleClass('view-list view-grid');
    $('.page-music-songs').toggleClass('mode-list mode-grid');
    $('.filters-box').toggleClass('bg-yellow');

    view_mode = (view_mode == 'grid') ? 'list' : 'grid';
  });

  $(document).on('click', '.song-list-action a', function(e){
    e.preventDefault();
    
    $(this).parent().append('<div class="loading">Loading</div>');

    $.ajax({
      url: $(this).attr('href'),
      type: 'GET',
      success: function(res) {
        $('.song-list .item-empty').remove();
        $('.song-list').append($(res).find('.song-list').html());
        $('.song-list-action').remove();
        $('.song-list').after($(res).find('.song-list-action'));
        $('.legacy-streaming-audio-player').streamingAudioPlayer();
      }
    });

    $(this).remove();
  });

  $('.filters-bar .filter-anchor').click(function(e){
    e.preventDefault();
    $('.filters-box').addClass('open');  
  });

  $('.filters-box .box-top .btn-reset').click(function(e){
    e.preventDefault();
    var filter_url = $('.filters-box').data('url');
    location = filter_url + '?song_mode=' + view_mode;
  });

  $('.filters-box .box-top .btn-close').click(function(e){
    e.preventDefault();
    $('.filters-box').removeClass('open');
  });

  $('.filter-like span').click(function(e){
    e.preventDefault();

    if (!$(this).hasClass('active')) {
      $(this).parent().find('span').removeClass('active');
      $(this).addClass('active');
      filter_songs();
    }    
  });

  $('.filter-like .like-reset').click(function(e){
    e.preventDefault();

    location = $(this).attr('href') + '?song_mode=' + view_mode;
  });

  $(window).scroll(function(){
    if (window.scrollY > 250) {
      $('#filter-like').addClass('sticky');
    } else {
      $('#filter-like').removeClass('sticky');
    }

    if ($('.song-list-action').length > 0 && window.scrollY + $(window).height() > $('.song-list-action').offset().top) {
      $('.song-list-action a').click();
    }
  });

  $('.filters-box .filter span').click(function(e){
    e.preventDefault();

    if (!$(this).hasClass('active')) {
      $(this).parent().find('span').removeClass('active');
      $(this).addClass('active');
      filter_songs();
    }    
  });

  function filter_songs() {
    var filter_url = $('.filters-box').data('url');
    var params = [];

    if ( $('#search-query').val() != '' ) {
      params.push( 'song_search=' + $('#search-query').val() );
    }

    if ($('#filter-like span.active').data('value')) {
      params.push( 'song_like=' + $('#filter-like span.active').data('value') );
    } else {
      params.push( 'song_orderby=' + $('#filter-order span.active').data('orderby') );
      params.push( 'song_order=' + $('#filter-order span.active').data('order') );
    }

    if ($('#filter-type span.active').data('value')) {
      params.push( 'song_type=' + $('#filter-type span.active').data('value') );
    }
    
    if ($('#filter-date span.active').data('value')) {
      params.push( 'song_date=' + $('#filter-date span.active').data('value') );
    }

    params.push( 'song_mode=' + view_mode );

    location = filter_url + '?' + params.join('&');
  }
}