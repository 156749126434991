import { initCustomCursor } from "./common";

export default function home_music() {
  $('.home-music .music-list').owlCarousel({
    items: 1.2,
    margin: 10,
    dotsEach: true,
    loop: true,
    nav: true,
    smartSpeed: 1000,
    mouseDrag: true,
    touchDrag: true,
    center: true,
    navText: ['<i class="far fa-angle-left"></i>','<i class="far fa-angle-right"></i>'],
    responsive: {
      768: {
        items: 2,
        margin: 120
      }
    }
  });

  // Custom Cursor
  initCustomCursor();
}