require( 'owl.carousel' );
require( 'jquery-mousewheel' );
require( 'jquery-touchswipe' );
import 'stupid-table-plugin';
import 'magnific-popup';
import 'select2';
import 'imagesloaded';
import AOS from 'aos';

import common from './modules/common';
import nav from './modules/nav';

import home_hero from './modules/home_hero';
import home_music from './modules/home_music';
import home_videos from './modules/home_videos';
import tour_current from './modules/tour_current';
import tour_pasts from './modules/tour_pasts';
import tour_single from './modules/tour_single';
import news_index from './modules/news_index';
import news_single from './modules/news_single';
import music_album_index from './modules/music_album_index';
import music_album_single from './modules/music_album_single';
import music_song_index from './modules/music_song_index';
import music_song_single from './modules/music_song_single';
import music_common from './modules/music_common';
import faq from './modules/faq';
import tour_gallery from './modules/tour_gallery';
import band from './modules/band';
import music_song_player from './modules/music_song_player';
import awards from './modules/awards';
import playlists from './modules/playlists';
import charts from './modules/charts';
import film_index from './modules/film_index';
import film_single from './modules/film_single';

$(document).ready(function($) {

  common();

  nav();

  if ($('body').hasClass('page-template-home')) {
    home_hero();
    home_music();
    home_videos();
  }

  if ($('body').hasClass('page-template-awards')) {
    awards();
  }

  if ($('body').hasClass('page-template-playlists')) {
    playlists();
  }

  if ($('body').hasClass('page-template-charts')) {
    charts();
  }

  if ($('body').hasClass('page-template-tours-current')) {
    tour_current();
  }

  if ($('body').hasClass('page-template-tours-past')) {
    tour_pasts();
  }

  if ($('body').hasClass('single-bruce_show')) {
    tour_single();
  }

  if ($('body').hasClass('page-template-news')) {
    news_index();
  }

  if ($('body').hasClass('single-post')) {
    news_single();
  }

  if ($('body').hasClass('page-template-music-albums')) {
    music_common();
    music_album_index();
  }

  if ($('body').hasClass('single-bruce_album')) {
    music_common();
    music_album_single();
    music_song_player();
  }

  if ($('body').hasClass('page-template-music-songs')) {
    music_common();
    music_song_index();
    music_song_player();
  }

  if ($('body').hasClass('single-bruce_song')) {
    music_common();
    music_song_single();
    music_song_player();
  }

  if ($('body').hasClass('page-template-filmography')) {
    film_index();
  }

  if ($('body').hasClass('single-bruce_film')) {
    film_single();
  }

  if ($('body').hasClass('page-template-faq')) {
    faq();
  }

  if ($('body').hasClass('tax-gallery') || $('body').hasClass('single-photo')) {
    tour_gallery();
  }

  if ($('body').hasClass('page-template-band') || $('body').hasClass('single-band_member')) {
    band();
  }

  AOS.init({
    duration: 1000,
    offest: 200,
    anchorPlacement: 'top-bottom'
  });

});

function alert(message) { 
  $.magnificPopup.open({
    items: {
      src: '<div class="bruce-alert">' + message + '</div>',
      type: 'inline'
    },
    closeOnBgClick: false
  });
}

window.alert = alert;