export default function tour_current() {
  $(document).on('click', '.tour-list .list-action a', function(e){
    e.preventDefault();
    
    $(this).parent().append('<div class="loading">Loading</div>');

    $.ajax({
      url: $(this).attr('href'),
      type: 'GET',
      success: function(res) {
        $('.tour-list .list-action').remove();
        $('.tour-list').append($(res).find('.tour-list').html());
      }
    });

    $(this).remove();
  });

  $(window).scroll(function(){
    if ($('.tour-list .list-action').length > 0 && window.scrollY + $(window).height() > $('.tour-list .list-action').offset().top) {
      $('.tour-list .list-action a').click();
    }
  });
}