import { initCustomCursor } from "./common";

export default function film_single() {

  var gallery_carousel = $('.film-gallery .gallery-carousel');

  gallery_carousel.imagesLoaded(function(){
    var gallery_w = 0;
    $( '.film-gallery .gallery-carousel .item img' ).each(function(){
      gallery_w += $(this).width();
    });

    if (gallery_w > $(window).width()) {
      gallery_carousel.addClass('owl-carousel');
      gallery_carousel.owlCarousel({
        items: 1.2,
        margin: 0,
        dotsEach: true,
        loop: false,
        nav: true,
        smartSpeed: 1000,
        mouseDrag: true,
        touchDrag: true,
        autoWidth: true,
        navText: ['<i class="fal fa-angle-left"></i>', '<i class="fal fa-angle-right"></i>'],
        responsive: {
          768: {
            items: 3,
          }
        }
      }).trigger('refresh.owl.carousel');

      initCustomCursor();
    } else {
      $( '.film-gallery .gallery-carousel .item' ).removeClass('custom-cursor');
    }
  });

  $('.film-videos .videos-list').owlCarousel({
    items: 1.2,
    margin: 20,
    dotsEach: true,
    loop: false,
    nav: true,
    smartSpeed: 1000,
    mouseDrag: true,
    touchDrag: true,
    autoWidth: true,
    navText: ['<i class="fal fa-angle-left"></i>','<i class="fal fa-angle-right"></i>'],
    responsive: {
      768: {
        items: 2.15,
        margin: 60
      }
    }
  });

  $('.film-videos .videos-list a').magnificPopup({
    type: 'iframe',
    mainClass: 'mfp-fade',
    removalDelay: 160,
    preloader: false,
    fixedContentPos: false,
    closeOnBgClick: false,
    callbacks: {
      open: function(){
        $('.lsapb-play .fa-pause').parent().click();
      }
    }
  });

  $('.film-upnext .music-list').owlCarousel({
    items: 1.2,
    margin: 10,
    dotsEach: true,
    loop: true,
    nav: true,
    smartSpeed: 1000,
    mouseDrag: true,
    touchDrag: true,
    center: true,
    navText: ['<i class="far fa-angle-left"></i>','<i class="far fa-angle-right"></i>'],
    responsive: {
      768: {
        items: 2.5,
        margin: 120
      }
    }
  });

  // Custom Cursor
  initCustomCursor();

  $('.film-awards .photos').each(function(){
    $(this).magnificPopup({
      delegate: 'a', // the selector for gallery item
      type: 'image',
      closeMarkup: '<button title="%title%" type="button" class="mfp-close">Close</button>',
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        preload: [0, 2],
        arrowMarkup: '<button class="btn-%dir% far fa-arrow-%dir%"></button>',
      },
      image: {
        markup: '<div class="mfp-figure">'+
          '<div class="mfp-close"></div>'+
          '<div class="mfp-img-wrapper"><div class="mfp-img"></div>'+
          '<div class="mfp-arrows"><a class="btn-prev" href="#"><i class="far fa-arrow-left"></i></a><a class="btn-next" href="#"><i class="far fa-arrow-right"></i></a></div></div>'+
          '<div class="mfp-bottom">'+
            '<div class="mfp-caption"></div>'+
          '</div>'+
        '</div>',
        verticalFit: true,
      },
      callbacks: {
        markupParse: function(template, values, item) {
          values.caption = item.el.data('caption');
        },
        imageLoadComplete: function() {
          if ($('.mfp-caption').html() == '') {
            $('.mfp-caption').hide();
          } else {
            $('.mfp-caption').show();
            $('.mfp-caption').width($('.mfp-img').width());
          }
        }
      }
    });
  });

  var _popup;

  $(document).on('click', '.mfp-arrows .btn-prev', function(e){
    e.preventDefault();
    
    $(_popup).magnificPopup('prev');
  });

  $(document).on('click', '.mfp-arrows .btn-next', function(e){
    e.preventDefault();
    
    $(_popup).magnificPopup('next');
  });  

  $('.film-awards .videos').each(function(){
    $(this).magnificPopup({
      delegate: 'a', // the selector for gallery item
      type: 'iframe',
      closeMarkup: '<button title="%title%" type="button" class="mfp-close">Close</button>',
      gallery: {
        enabled: true,
        arrowMarkup: '<button class="btn-%dir% far fa-arrow-%dir%"></button>',
      },
      iframe: {
        markup: '<div class="mfp-iframe-wrapper">'+
          '<div class="mfp-close"></div>'+
          '<div class="mfp-iframe-scaler">'+
          '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>'+
          '<div class="mfp-arrows"><a class="btn-prev" href="#"><i class="far fa-arrow-left"></i></a><a class="btn-next" href="#"><i class="far fa-arrow-right"></i></a></div></div>'+
          '<div class="mfp-bottom"></div>'+
          '</div>'+
        '</div>',
        verticalFit: true,
      },
      callbacks: {
        markupParse: function(template, values, item) {
          values.bottom = $(item.el).html();
        }
      }
    });
  });

  $('.link-photos').click(function(e){
    e.preventDefault();
    _popup = $(this).parent().find('.photos');
    $(this).parent().find('.photos a').first().click();
  });

  $('.link-videos').click(function(e){
    e.preventDefault();
    _popup = $(this).parent().find('.videos');
    $(this).parent().find('.videos a').first().click();
  });

}