import { isMobile } from "./common";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

export default function music_song_single() {

  if (isMobile()) {
    if ($('.albums-container .block-content .item').length > 1) {
      $('.albums-container .block-content').addClass('owl-carousel');
      $('.albums-container .block-content').owlCarousel({
        items: 1.2,
        margin: 20,
        dotsEach: true,
        loop: true,
        nav: true,
        smartSpeed: 1000,
        mouseDrag: true,
        touchDrag: true,
        navText: ['<i class="fal fa-angle-left"></i>', '<i class="fal fa-angle-right"></i>'],
      }); 
    }
  }

  if ($('.lyrics-container .lyrics-images .item').length > 1) {
    $('.lyrics-container .lyrics-images').addClass('owl-carousel');
    $('.lyrics-container .lyrics-images').owlCarousel({
      items: 1,
      margin: 20,
      dotsEach: true,
      loop: true,
      nav: true,
      smartSpeed: 1000,
      mouseDrag: true,
      touchDrag: true,
      navText: ['<i class="fal fa-angle-left"></i>', '<i class="fal fa-angle-right"></i>'],
    });
  }

  $('.song-videos .videos-list').owlCarousel({
    items: 1.2,
    margin: 20,
    dotsEach: true,
    loop: false,
    nav: true,
    smartSpeed: 1000,
    mouseDrag: true,
    touchDrag: true,
    autoWidth: true,
    navText: ['<i class="fal fa-angle-left"></i>','<i class="fal fa-angle-right"></i>'],
    responsive: {
      768: {
        items: 2.15,
        margin: 60
      }
    }
  });

  // gsap.registerPlugin(ScrollTrigger);

  // $('.videos-list').width($('.videos-list .item').width() * $('.videos-list .item').length + 30 * ($('.videos-list .item').length - 1));
  // console.log($('.song-videos').width() - $('.videos-list').width());
  
  // gsap.to($('.videos-list'), {
  //   x: $('.song-videos .container').width() - $('.videos-list').width(),
  //   ease: "none",
  //   scrollTrigger: {
  //     trigger: $('.song-videos'),
  //     start: 'bottom bottom',
  //     pin: true,
  //     scrub: 0.1,
  //   }
  // });

  $('.song-videos .videos-list a').magnificPopup({
    type: 'iframe',
    mainClass: 'mfp-fade',
    removalDelay: 160,
    preloader: false,
    fixedContentPos: false,
    closeOnBgClick: false,
    callbacks: {
      open: function(){
        $('.lsapb-play .fa-pause').parent().click();
      }
    }
  });

  $('.song-performances .load-more a').click(function(e){
    e.preventDefault();

    $('.song-performances table').addClass('expanded');
    $('.song-performances .load-more').hide();
  });

}