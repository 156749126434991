import { initCustomCursor, isMobile } from "./common";

export default function music_album_index() {
  initCustomCursor();

  $('.filters-bar .filter-anchor').click(function(e){
    e.preventDefault();
  });

  $('.filters-bar .view-mode').click(function(e){
    e.preventDefault();

    $('.page-music-albums').toggleClass('mode-slide mode-grid');
    $(this).toggleClass('view-slide view-grid');

    view_mode = (view_mode == 'slide') ? 'grid' : 'slide';

    // $('.album-list').attr('style', '');

    // var scroll_pos = 0;
    // if ($('.page-music-albums').hasClass('mode-grid')) {
    //   $('.album-list').css('opacity', '0');
    //   var active_id = $('.album-slides .item.active').attr('id');
    //   var active_ele = $('.album-list .item[data-id=' + active_id + ']');
    //   scroll_pos = active_ele.offset().top - $(window).height() / 2 + active_ele.height() / 2;

    //   var transform_x = active_ele.position().left + active_ele.width() / 2;
    //   var transform_y = active_ele.position().top + active_ele.height() / 2;
    //   $('.album-list').css('transform-origin', transform_x + 'px ' + transform_y + 'px');

    //   setTimeout(function(){
    //     $('.album-list').css('transform', 'scale(0)');
    //     $('.album-list').css('opacity', '1');
    //     setTimeout(function(){
    //       $('.album-list').css('transition', 'all 350ms linear');
          
    //       $('.album-list').css('transform', 'scale(1)');
    //     }, 100);
    //   }, 100);      
    // }

    $('html, body').animate({
      scrollTop: 0
    }, 0);
  
  });

  var loading = false;

  $(document).on('click', '.album-list-action a', function(e){
    e.preventDefault();

    if (loading) return;

    loading = true;
    
    $(this).parent().append('<div class="loading">Loading</div>');

    $.ajax({
      url: $(this).attr('href'),
      type: 'GET',
      success: function(res) {
        $('.album-list .item-empty').remove();
        $('.album-list').append($(res).find('.album-list').html());
        $('.album-list-action').remove();
        $('.album-list').after($(res).find('.album-list-action'));
        $('.album-slides').append($(res).find('.album-slides').html());
        initCustomCursor();
        loading = false;
      }
    });
  });

  $(window).scroll(function(){
    if ($('.album-list-action').length > 0 && $('.page-music-albums').hasClass('mode-grid') && window.scrollY + $(window).height() > $('.album-list-action').offset().top) {
      $('.album-list-action a').click();
    }
  });

  $('.filters-bar .filter-anchor').click(function(e){
    e.preventDefault();
    $('.filters-box').addClass('open');  
  });

  $('.filters-box .box-top .btn-reset').click(function(e){
    e.preventDefault();
    var filter_url = $('.filters-box').data('url');
    location = filter_url + '?album_mode=' + view_mode;
  });

  $('.filters-box .box-top .btn-close').click(function(e){
    e.preventDefault();
    $('.filters-box').removeClass('open');
  });

  $('.filters-box .filter span').click(function(e){
    e.preventDefault();

    if (!$(this).hasClass('active')) {
      $(this).parent().find('span').removeClass('active');
      $(this).addClass('active');
      filter_albums();
    }    
  });

  function filter_albums() {
    var filter_url = $('.filters-box').data('url');
    var params = [];

    if ($('#filter-type span.active').data('value')) {
      params.push( 'album_type=' + $('#filter-type span.active').data('value') );
    }
    
    if ($('#filter-date span.active').data('value')) {
      params.push( 'album_date=' + $('#filter-date span.active').data('value') );
    }

    params.push( 'album_orderby=' + $('#filter-order span.active').data('orderby') );
    params.push( 'album_order=' + $('#filter-order span.active').data('order') );

    params.push( 'album_mode=' + view_mode );

    location = filter_url + '?' + params.join('&');
  }

  initSlide();

  function initSlide() {
    var id = $('.album-slides .item').first().attr('id');
    goToSlide('#' + id);
  }

  function goToSlide(slide_id) {
    var first = $('.album-slides .item').first();
    var current = $(slide_id);
    var next1 = current.next().length ? current.next() : first;
    var next2 = next1.next().length ? next1.next() : first;
    
    $('.album-slides .item').removeClass('active next1 next2');
    current.addClass('active');
    next1.addClass('next1');
    next2.addClass('next2');

    $('.album-slides-heading').removeClass('show');

    setTimeout(function(){
      $('.album-slides-heading').addClass('show');
      $('.album-slides-heading').html(current.data('title'));
      $('.page-container').css('background-color', current.data('bgcolor'));
      $('.album-slides-heading').css('color', current.data('txtcolor'));
    }, 250);
  }

  var wheel_timestamp = 0;

  $('.album-slides').mousewheel(function(e){
    e.preventDefault();

    if (e.timeStamp - wheel_timestamp < 700) return;

    wheel_timestamp = e.timeStamp;
    
    moveSlide(e.deltaY);
  });

  function moveSlide(dir) {
    var current = $('.album-slides .item.active');
    var new_current, new_next1, new_next2;
    var first = $('.album-slides .item').first();
    var last = $('.album-slides .item').last();
  
    $('.album-slides .item').removeClass('active next1 next2 close');

    if (dir == -1 || dir == 'down') {
      new_current = current.next().length ? current.next() : first;
      new_next1 = new_current.next().length ? new_current.next() : first;
      new_next2 = new_next1.next().length ? new_next1.next() : first;
      current.addClass('close');
    } else {
      new_current = current.prev().length ? current.prev() : last;
      new_next1 = new_current.next().length ? new_current.next() : first;
      new_next2 = new_next1.next().length ? new_next1.next() : first;
    }

    new_current.addClass('active');
    new_next1.addClass('next1');
    new_next2.addClass('next2');

    $('.album-slides-heading').removeClass('show');

    setTimeout(function(){
      $('.album-slides-heading').addClass('show');
      $('.album-slides-heading').html(new_current.data('title'));
      $('.page-container').css('background-color', new_current.data('bgcolor'));
      $('.album-slides-heading').css('color', new_current.data('txtcolor'));
    }, 250);
    
    var load = new_next2.next().next().next().next();

    if (load.length == 0) {
      $('.album-list-action a').click();
    }
  }

  $(document).on('click', '.album-list .item', function(e){
    e.preventDefault();

    // goToSlide($(this).find('.item-image a').attr('href'));
    // $('.filters-bar .view-mode').click();
    
    location = $(this).find('.item-image a').attr('href');
  });

  $(document).on('click', '.album-slides .item', function(e){
    e.preventDefault();

    location = $(this).find('a').attr('href');
  });


  if (isMobile()) {
    $('.album-slides').swipe({
      swipe: function(event, direction, distance, duration, fingerCount, fingerData) {
        event.preventDefault();
        moveSlide(direction);
      }
    });
  }
}