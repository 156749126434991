import { isMobile } from "./common";

export default function nav() {
	
	$('#header .link-menu a').on('click', function (e) {
		e.preventDefault();
    $('body').toggleClass( 'menu-open' );
		$( '.menu-container' ).toggleClass( 'active' );
  });

  $('#footer .scroll-top').click(function(){
    $('html, body').animate({
      scrollTop: 0
    }, 800);
  });

  $('.menu .menu-item-has-children > a').click(function(e){
    e.preventDefault();

    if (isMobile()) {
      $(this).parent().toggleClass('open');
    }    
  });

  $('.tour-tabs .has-menu h4').click(function(e){
    e.preventDefault();
  });
  
}