import { initCustomCursor } from './common';

export default function band() {

  $( '.band-gallery .gallery-carousel' ).imagesLoaded(function(){
    var gallery_w = 0;
    $( '.band-gallery .gallery-carousel .item img' ).each(function(){
      gallery_w += $(this).width();
    });

    if (gallery_w > $(window).width()) {
      $( '.band-gallery .gallery-carousel' ).addClass('owl-carousel');		
      $( '.band-gallery .gallery-carousel' ).owlCarousel( {
        items: 1,
        margin: 0,
        dotsEach: true,
        loop: false,
        nav: true,
        smartSpeed: 1000,
        mouseDrag: true,
        touchDrag: true,
        autoWidth: false,
        navText: ['<i class="fal fa-angle-left"></i>', '<i class="fal fa-angle-right"></i>'],
        responsive: {
          768: {
            items: 3,
            autoWidth: true,
          }
        }
      } );
    } else {
      $( '.band-gallery .gallery-carousel .item' ).removeClass('custom-cursor');
    }
  });

  initCustomCursor();

  $('.band-members .item a').hover(function(){
    var mc = $('#' + $(this).data('mc'));
    mc.addClass('hover');
    var mc_img = mc.find('.member-category__title img');
    var mc_img_container = mc.find('.member-category__title');
    mc_img.attr('src', $(this).data('image'));
    mc_img.width(mc_img_container.width() * 0.8);
    mc_img.css('top', $(window).height() / 2);
    mc_img.css('left', mc_img_container.offset().left + mc_img_container.width() / 2);
    mc_img.show();
  }, function(){
    var mc = $('#' + $(this).data('mc'));
    mc.removeClass('hover');
    var mc_img = mc.find('.member-category__title img');
    mc_img.hide();
  });

  $(window).scroll(function(){
    if ($('body').hasClass('single-band_member')) {
      var window_h = $(window).height();
      var title_y = $('.band-member__title-name').position().top + $('.band-member__title-name').outerHeight() + 150;
      var opacity = 1;

      if (window_h > title_y) {
        opacity = 1 - window.scrollY / (window_h / 2);
      } else if (window_h + window.scrollY > title_y) {
        opacity = 1 - (window_h + window.scrollY - title_y) / (window_h * 0.9);
      }

      $('.band-member__title-name').css('opacity', opacity);
    }
  });
}

