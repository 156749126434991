export default function home_videos() {
  $('.home-videos .videos-list').owlCarousel({
    items: 1.2,
    margin: 20,
    dotsEach: true,
    loop: true,
    nav: true,
    smartSpeed: 1000,
    mouseDrag: true,
    touchDrag: true,
    navText: ['<i class="fal fa-arrow-left"></i>','<i class="fal fa-arrow-right"></i>'],
    responsive: {
      768: {
        items: 2.15,
        margin: 60
      }
    }
  });

  $('.home-videos .videos-list a').magnificPopup({
    type: 'iframe',
    mainClass: 'mfp-fade',
    removalDelay: 160,
    preloader: false,
    fixedContentPos: false,
    closeOnBgClick: false
  });
}