import { isMobile } from './common';
import Masonry from 'masonry-layout';

export default function tour_gallery() {
  function initMasonry() {
    $('.gallery-photos').imagesLoaded(function(){
      var gutter = isMobile() ? 0 : $('.gallery-photos').width() * 0.02;
      new Masonry('.gallery-photos', {
        itemSelector: '.item',
        columnWidth: '.item',
        percentPosition: true,
        gutter: gutter
      });

      $('.gallery-photos .item a').click(function(e){
        e.preventDefault();

        var id = $(this).data('id');
        var gallery_index = 0;
        $(gallery_photos).each(function(i){
          if (this.id == id) {
            gallery_index = i;
            return false;
          }
        });

        $.magnificPopup.open({
          delegate: 'a',
          type: 'image',
          closeMarkup: '<button title="%title%" type="button" class="mfp-close">Close</button>',
          items: gallery_photos,
          gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0, 2],
            arrowMarkup: '<button class="btn-%dir% far fa-arrow-%dir%"></button>',
          },
          image: {
            markup: '<div class="mfp-figure">'+
              '<div class="mfp-close"></div>'+
              '<div class="mfp-img-wrapper"><div class="mfp-img"></div>'+
              '<div class="mfp-arrows"><a class="btn-prev" href="#"><i class="far fa-arrow-left"></i></a><a class="btn-next" href="#"><i class="far fa-arrow-right"></i></a></div></div>'+
              '<div class="mfp-bottom">'+
                '<div class="mfp-title"></div>'+
                '<div class="mfp-credit"></div>'+
              '</div>'+
            '</div>',
            verticalFit: true,
          },
          callbacks: {
            markupParse: function(template, values, item) {
              values.title = item.data.title;
              values.credit = 'Photo Credit: <a target="_blank" href="' + item.data.credit_url + '">' + item.data.credit + '</a>';
            },
            imageLoadComplete: function() {
              $('.mfp-title').width($('.mfp-img').width());
            }
          },
        }, gallery_index);
      });
    });
  }

  initMasonry();

  $(document).on('click', '.mfp-arrows .btn-prev', function(e){
    e.preventDefault();
    
    $('.gallery-photos').magnificPopup('prev');
  });

  $(document).on('click', '.mfp-arrows .btn-next', function(e){
    e.preventDefault();
    
    $('.gallery-photos').magnificPopup('next');
  });

  $(document).on('click', '.gallery-photos > a', function(e){
    e.preventDefault();
    
    $(this).append('<i class="fal fa-spinner-third fa-spin"></i>');

    $.ajax({
      url: $(this).attr('href'),
      type: 'GET',
      success: function(res) {
        $('.gallery-photos > a').remove();
        $('.gallery-photos').append($(res).find('.gallery-photos').html());
        initMasonry();
      }
    });
  });

  $('.filters .custom-select').each(function(){
    var _instance = this;
    $(this).select2();
    $(this).on('select2:closing', function(e){
      $(_instance).parents('.filter').removeClass('active');
    });
  });

  $('.filters .filter-anchor').mousedown(function(e){
    e.preventDefault();
    e.stopPropagation();

    var _filter = $(this).parent();
    var _select = $($(this).attr('href'));

    if (_filter.hasClass('active')) {
      _select.select2('close');
    } else {
      _filter.addClass('active');
      _select.select2('open');
    }
  });

  $('.filters .filter-anchor').click(function(e){
    e.preventDefault();
  });

  $('.filters select').on('select2:select', function (e) {
    filter_gallery();
  });

  function filter_gallery() {
    var filter_url = $('.filters').data('url');
    var params = [];

    if ( $('#filter-sort').val() != '' ) {
      params.push( 'gallery_sort=' + $('#filter-sort').val() );
    }

    location = filter_url + '?' + params.join('&');
  }

  
  
  // Photo Single Page

  if ($('body').hasClass('single-photo')) {
    $.magnificPopup.open({
      delegate: 'a',
      type: 'image',
      closeMarkup: '<button title="%title%" type="button" class="mfp-close">Close</button>',
      items: gallery_photos,
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        preload: [0, 2],
        arrowMarkup: '<button class="btn-%dir% far fa-arrow-%dir%"></button>',
      },
      image: {
        markup: '<div class="mfp-figure">'+
          '<div class="mfp-close"></div>'+
          '<div class="mfp-img-wrapper"><div class="mfp-img"></div>'+
          '<div class="mfp-arrows"><a class="btn-prev" href="#"><i class="far fa-arrow-left"></i></a><a class="btn-next" href="#"><i class="far fa-arrow-right"></i></a></div></div>'+
          '<div class="mfp-bottom">'+
            '<div class="mfp-title"></div>'+
            '<div class="mfp-credit"></div>'+
          '</div>'+
        '</div>',
        verticalFit: true,
      },
      callbacks: {
        markupParse: function(template, values, item) {
          values.title = item.data.title;
          values.credit = 'Photo Credit: <a target="_blank" href="' + item.data.credit_url + '">' + item.data.credit + '</a>';
        },
        imageLoadComplete: function() {
          $('.mfp-title').width($('.mfp-img').width());
        }
      },
    }, gallery_index);
  }
}