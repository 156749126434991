import Masonry from 'masonry-layout';
import { isMobile } from "./common";

export default function news_index() {
  $('.search-anchor').click(function(e){
    e.preventDefault();

    if ( $('#search-query').val() != '' ) {
      filter_news();
    } else {
      $('.page-title').toggleClass('search-open');
      $('.page-container').toggleClass('page-search-open');
    }
  });

  $('#search-query').keypress(function (e) {
    if ( e.keyCode == 13 ) {
      filter_news();
    }
  });

  $('#search-query').focus(function(){
    $('.page-container').addClass('page-search-open');
  });

  
  $('.filters .custom-select').each(function(){
    var _instance = this;
    $(this).select2();
    $(this).on('select2:closing', function(e){
      $(_instance).parents('.filter').removeClass('active');
    });
  });

  $('.filters .filter-anchor').mousedown(function(e){
    e.preventDefault();
    e.stopPropagation();

    var _filter = $(this).parent();
    var _select = $($(this).attr('href'));

    if (_filter.hasClass('active')) {
      _select.select2('close');
    } else {
      _filter.addClass('active');
      _select.select2('open');
    }
  });

  $('.filters .filter-anchor').click(function(e){
    e.preventDefault();
  });

  $('.filters select').on('select2:select', function (e) {
    filter_news();
  });

  // function mobileFix() {
  //   if (isMobile()) {
  //     $('.news-list .item').each(function(){
  //       var _instance = this;
  //       $(this).find('a').click(function(){
  //         $(_instance).addClass('active');
  //       });
  //     });
  //   }
  // }

  function initMasonry() {
    new Masonry('.news-list', {
      itemSelector: '.item',
      columnWidth: '.item',
      percentPosition: true,
      gutter: 40
    });

    // mobileFix();
  }

  if ($('.news-list .no-results').length == 0) {
    $('.news-list').imagesLoaded(function(){
      initMasonry();
    });
  }

  $(document).on('click', '.news-list-action a', function(e){
    e.preventDefault();
    
    $(this).append('<i class="fal fa-spinner-third fa-spin"></i>');

    $.ajax({
      url: $(this).attr('href'),
      type: 'GET',
      success: function(res) {
        $('.news-list').append($(res).find('.news-list').html());
        $('.news-list-action').remove();
        $('.news-list').after($(res).find('.news-list-action'));

        $('.news-list').imagesLoaded(function(){
          initMasonry();
        });
      }
    });
  });

  function filter_news() {
    var filter_url = $('.filters').data('url');
    var params = [];

    if ( $('#search-query').val() != '' ) {
      params.push( 'news_search=' + $('#search-query').val() );
    }

    if ( $('#filter-type').val() != '' ) {
      params.push( 'news_type=' + $('#filter-type').val() );
    }

    if ( $('#filter-topic').val() != '' ) {
      params.push( 'news_topic=' + $('#filter-topic').val() );
    }

    if ( $('#filter-date').val() != '' ) {
      params.push( 'news_date=' + $('#filter-date').val() );
    }

    location = filter_url + '?' + params.join('&');
  }
}