export default function awards() {
  $('.filters .custom-select').select2();

  $('.filters .filter-anchor').click(function(e){
    e.preventDefault();
    
    $($(this).attr('href')).select2('open');
  });

  $('.filters select').on('select2:select', function (e) {
    filter_awards();
  });

  $(document).on('click', '.featured-content__action a', function(e){
    e.preventDefault();
    
    $(this).append('<i class="fal fa-spinner-third fa-spin"></i>');

    $.ajax({
      url: $(this).attr('href'),
      type: 'GET',
      success: function(res) {
        $('.featured-content__list').append($(res).find('.featured-content__list').html());
        $('.featured-content__action').remove();
        $('.featured-content__list').after($(res).find('.featured-content__action'));
      }
    });
  });

  $('.awards-list .photos').each(function(){
    $(this).magnificPopup({
      delegate: 'a', // the selector for gallery item
      type: 'image',
      closeMarkup: '<button title="%title%" type="button" class="mfp-close">Close</button>',
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        preload: [0, 2],
        arrowMarkup: '<button class="btn-%dir% far fa-arrow-%dir%"></button>',
      },
      image: {
        markup: '<div class="mfp-figure">'+
          '<div class="mfp-close"></div>'+
          '<div class="mfp-img-wrapper"><div class="mfp-img"></div>'+
          '<div class="mfp-arrows"><a class="btn-prev" href="#"><i class="far fa-arrow-left"></i></a><a class="btn-next" href="#"><i class="far fa-arrow-right"></i></a></div></div>'+
          '<div class="mfp-bottom">'+
            '<div class="mfp-caption"></div>'+
          '</div>'+
        '</div>',
        verticalFit: true,
      },
      callbacks: {
        markupParse: function(template, values, item) {
          values.caption = item.el.data('caption');
        },
        imageLoadComplete: function() {
          if ($('.mfp-caption').html() == '') {
            $('.mfp-caption').hide();
          } else {
            $('.mfp-caption').show();
            $('.mfp-caption').width($('.mfp-img').width());
          }
        }
      }
    });
  });

  var _popup;

  $(document).on('click', '.mfp-arrows .btn-prev', function(e){
    e.preventDefault();
    
    $(_popup).magnificPopup('prev');
  });

  $(document).on('click', '.mfp-arrows .btn-next', function(e){
    e.preventDefault();
    
    $(_popup).magnificPopup('next');
  });  

  $('.awards-list .videos').each(function(){
    $(this).magnificPopup({
      delegate: 'a', // the selector for gallery item
      type: 'iframe',
      closeMarkup: '<button title="%title%" type="button" class="mfp-close">Close</button>',
      gallery: {
        enabled: true,
        arrowMarkup: '<button class="btn-%dir% far fa-arrow-%dir%"></button>',
      },
      iframe: {
        markup: '<div class="mfp-iframe-wrapper">'+
          '<div class="mfp-close"></div>'+
          '<div class="mfp-iframe-scaler">'+
          '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>'+
          '<div class="mfp-arrows"><a class="btn-prev" href="#"><i class="far fa-arrow-left"></i></a><a class="btn-next" href="#"><i class="far fa-arrow-right"></i></a></div></div>'+
          '<div class="mfp-bottom"></div>'+
          '</div>'+
        '</div>',
        verticalFit: true,
      },
      callbacks: {
        markupParse: function(template, values, item) {
          values.bottom = $(item.el).html();
        }
      }
    });
  });

  $('.link-photos').click(function(e){
    e.preventDefault();
    _popup = $(this).parent().find('.photos');
    $(this).parent().find('.photos a').first().click();
  });

  $('.link-videos').click(function(e){
    e.preventDefault();
    _popup = $(this).parent().find('.videos');
    $(this).parent().find('.videos a').first().click();
  });

  $('.filter-reset').click(function(e){
    e.preventDefault();
    $('.filters select').val('');
    filter_awards();
  });

  function filter_awards() {
    var $wrapper = $('.awards-list');
    var type = $('#filter-type').val();
    var year = $('#filter-year').val();

    $wrapper.find('.awards-list-group, tr').removeClass('hide');

    if (type != '') {
      $wrapper.find('tr').each(function(){
        if ($(this).data('type') != type) {
          $(this).addClass('hide');
        }
      });
    }

    if (year != '') {
      $wrapper.find('tr').each(function(){
        if ($(this).data('year') != year) {
          $(this).addClass('hide');
        }
      });
    }

    $wrapper.find('table').each(function(){
      if ($(this).find('tr').length == $(this).find('.hide').length) {
        $(this).parents('.awards-list-group').addClass('hide');
      }
    });

    $('html, body').animate({
      scrollTop: $('.awards-list').offset().top - $('#header').outerHeight()
    }, 800);
  }
}