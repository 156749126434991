import { initCustomCursor, isMobile } from "./common";

export default function music_album_single() {
  $(window).scroll(function(){
    if (!isMobile()) {
      var img_h = $('.album-image').height();

      if (window.scrollY > 120 && window.scrollY < img_h + 120) {
        var scale = 1 - (window.scrollY - 120) / img_h * 0.5;
        $('.album-image img').css('transform', 'scale(' + scale + ')');
      }

      if (window.scrollY > img_h * 0.8) {
        $('.album-image-container').addClass('past');
      } else {
        $('.album-image-container').removeClass('past');
      }
    }
  });

  $('.album-image__action a').click(function(e){
    e.preventDefault();
    $('.album-image').toggleClass('back');
    
    if ($('.album-image').hasClass('back')) {
      $(this).html('Flip To View Front');
    } else {
      $(this).html('Flip To View Back');
    }
  });

  var gallery_carousel = $('.album-gallery .gallery-carousel');

  gallery_carousel.imagesLoaded(function(){
    var gallery_w = 0;
    $( '.album-gallery .gallery-carousel .item img' ).each(function(){
      gallery_w += $(this).width();
    });

    if (gallery_w > $(window).width()) {
      gallery_carousel.addClass('owl-carousel');
      gallery_carousel.owlCarousel({
        items: 1.2,
        margin: 0,
        dotsEach: true,
        loop: false,
        nav: true,
        smartSpeed: 1000,
        mouseDrag: true,
        touchDrag: true,
        autoWidth: true,
        navText: ['<i class="fal fa-angle-left"></i>', '<i class="fal fa-angle-right"></i>'],
        responsive: {
          768: {
            items: 3,
          }
        }
      }).trigger('refresh.owl.carousel');

      initCustomCursor();
    } else {
      $( '.album-gallery .gallery-carousel .item' ).removeClass('custom-cursor');
    }
  });

  $('.album-upnext .music-list').owlCarousel({
    items: 1.2,
    margin: 10,
    dotsEach: true,
    loop: true,
    nav: true,
    smartSpeed: 1000,
    mouseDrag: true,
    touchDrag: true,
    center: true,
    navText: ['<i class="far fa-angle-left"></i>','<i class="far fa-angle-right"></i>'],
    responsive: {
      768: {
        items: 2,
        margin: 120
      }
    }
  });

  // Custom Cursor
  initCustomCursor();
}