export default function common() {
  var outer_symbol_svg = '<svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 9L9 2" stroke="#E8E7E2" stroke-width="2" stroke-linecap="square"></path><path d="M2 1H10V9" stroke="#E8E7E2" stroke-width="2" stroke-linecap="square"></path></svg>';
  $('.link-store a').append(outer_symbol_svg);

  // $('.link-subscribe a').magnificPopup({
  //   type: 'inline',
  //   closeOnBgClick: false
  // });

  // Switch header and stikcy footer color
  $(window).scroll(function(){
    if (window.scrollY > 10) {
      $('#header, .sticky-footer').addClass('sticky');
    } else {
      $('#header, .sticky-footer').removeClass('sticky');
    }

    // var f_header = false, f_footer = false;
    // $('#header, .sticky-footer').removeClass('dark');

    // $('.bg-yellow').each(function(){
    //   var section_t = $(this).position().top;
    //   var section_b = $(this).position().top + $(this).height();
    //   var screen_t = window.scrollY;
    //   var screen_b = window.scrollY + $(window).height();

    //   if (!f_header && section_t < screen_t && section_b > screen_t) {
    //     $('#header').addClass('dark');
    //     f_header = true;
    //   }

    //   if (!f_footer && section_t < screen_b && section_b > screen_b) {
    //     $('.sticky-footer').addClass('dark');
    //     f_footer = true;
    //   }
    // });
  });

  // Social popup open
  $('.share-bar > a').click(function(e){
    e.preventDefault();

    $('.share-bar').toggleClass('open');
  });

	$('.share-bar ul li a').click(function(e){
		e.preventDefault();
		const link = $(this).attr("href");
		const w = 670;
		const h = 378;
		const y = window.outerHeight / 2 + window.screenY - (h / 2)
		const x = window.outerWidth / 2 + window.screenX - (w / 2)
		window.open(link, "_blank", 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + w + ', height=' + h + ', top=' + y + ', left=' + x);
	});

}

const isMobile = () => {
  return $(window).width() < 768;
}

const initCustomCursor = () => {
  // Custom Cursor
  $('.custom-cursor').each(function(e){
    if ($(this).find('.cursor').length == 0) {
      $(this).append('<div class="cursor">' + $(this).data('cursor') + '</div>');
    }
  });

  $('.custom-cursor').mousemove(function(){
    $(this).addClass('hover');
  });

  $('.custom-cursor').hover(function(){
    $(this).addClass('hover');
  }, function(){
    $(this).removeClass('hover');
  });

  $('.custom-cursor').mousemove(function(e){
    if (e.pageX >= $(this).offset().left && e.pageX <= $(this).offset().left + $(this).width() && e.pageY >= $(this).offset().top && e.pageY <= $(this).offset().top + $(this).height()) {
      var _cursor = $(this).find('.cursor');
      _cursor.css('left', e.pageX - $(this).offset().left);
      _cursor.css('top', e.pageY - $(this).offset().top);
    } else {
      $(this).removeClass('hover');
    }
  });
}

export { isMobile, initCustomCursor };