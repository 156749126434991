import { initCustomCursor } from "./common";

export default function film_index() {
  initCustomCursor();

  $('.search-anchor').click(function(e){
    e.preventDefault();

    if ( $('#search-query').val() != '' ) {
      filter_films();
    } else {
      $('.page-title').toggleClass('search-open');
      $('.page-container').toggleClass('page-search-open');
    }
  });

  $('#search-query').keypress(function (e) {
    if ( e.keyCode == 13 ) {
      filter_films();
    }
  });

  $('#search-query').focus(function(){
    $('.page-container').addClass('page-search-open');
  });

  $('.filters-bar .filter-anchor').click(function(e){
    e.preventDefault();
  });

  var loading = false;

  $(document).on('click', '.film-list-action a', function(e){
    e.preventDefault();

    if (loading) return;

    loading = true;
    
    $(this).parent().append('<div class="loading">Loading</div>');

    $.ajax({
      url: $(this).attr('href'),
      type: 'GET',
      success: function(res) {
        $('.film-list .item-empty').remove();
        $('.film-list').append($(res).find('.film-list').html());
        $('.film-list-action').remove();
        $('.film-list').after($(res).find('.film-list-action'));
        initCustomCursor();
        loading = false;
      }
    });
  });


  $('.filters-bar .filter-anchor').click(function(e){
    e.preventDefault();
    $('.filters-box').addClass('open');  
  });

  $('.filters-box .box-top .btn-reset').click(function(e){
    e.preventDefault();
    var filter_url = $('.filters-box').data('url');
    location = filter_url;
  });

  $('.filters-box .box-top .btn-close').click(function(e){
    e.preventDefault();
    $('.filters-box').removeClass('open');
  });

  $('.filters-box .filter span').click(function(e){
    e.preventDefault();

    if (!$(this).hasClass('active')) {
      $(this).parent().find('span').removeClass('active');
      $(this).addClass('active');
      filter_films();
    }    
  });

  $(window).scroll(function(){
    if ($('.film-list-action').length > 0 && window.scrollY + $(window).height() > $('.film-list-action').offset().top) {
      $('.film-list-action a').click();
    }
  });

  function filter_films() {
    var filter_url = $('.filters-box').data('url');
    var params = [];

    if ( $('#search-query').val() != '' ) {
      params.push( 'film_search=' + $('#search-query').val() );
    }

    if ($('#filter-type span.active').data('value')) {
      params.push( 'film_type=' + $('#filter-type span.active').data('value') );
    }
    
    if ($('#filter-date span.active').data('value')) {
      params.push( 'film_date=' + $('#filter-date span.active').data('value') );
    }

    params.push( 'film_orderby=' + $('#filter-order span.active').data('orderby') );
    params.push( 'film_order=' + $('#filter-order span.active').data('order') );

    location = filter_url + '?' + params.join('&');
  }

  $(document).on('click', '.film-list .item:not(.item-empty)', function(e){
    e.preventDefault();
    
    location = $(this).find('.item-image a').attr('href');
  });
}