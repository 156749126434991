import { initCustomCursor } from "./common";

export default function playlists() {
  initCustomCursor();

  $('.search-anchor').click(function(e){
    e.preventDefault();

    if ( $('#search-query').val() != '' ) {
      filter_playlists('search');
    } else {
      $('.page-title').toggleClass('search-open');
      $('.page-container').toggleClass('page-search-open');
    }
  });

  $('#search-query').keypress(function (e) {
    if ( e.keyCode == 13 ) {
      filter_playlists('search');
    }
  });

  $('#search-query').focus(function(){
    $('.page-container').addClass('page-search-open');
  });

  $('.playlist-types a:not(.filter-reset)').click(function(e){
    e.preventDefault();

    $('.playlist-types a').removeClass('active');
    $(this).addClass('active');

    filter_playlists('type');
  });

  $('.playlist-types a.filter-reset').click(function(e){
    e.preventDefault();

    $('.playlist-types a').removeClass('active');
    $('.playlist-types a').first().addClass('active');

    filter_playlists('type');
  });

  $(document).on('click', '.playlist-list-action a', function(e){
    e.preventDefault();
    
    $(this).append('<i class="fal fa-spinner-third fa-spin"></i>');

    $.ajax({
      url: $(this).attr('href'),
      type: 'GET',
      success: function(res) {
        $('.playlist-list').append($(res).find('.playlist-list').html());
        $('.playlist-list-action').remove();
        $('.playlist-list').after($(res).find('.playlist-list-action'));
        initCustomCursor();
      }
    });
  });

  function filter_playlists(mode) {
    var filter_url = $('.filters').data('url');
    var params = [];

    if ( mode == 'search' && $('#search-query').val() != '' ) {
      params.push( 'playlist_search=' + $('#search-query').val() );
    } else {
      params.push( 'playlist_type=' + $('.playlist-types .active').data('type') );
    }    

    location = filter_url + '?' + params.join('&');
  }
}