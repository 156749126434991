export default function tour_pasts() {
  $('.tour-list .group-toggle').click(function(e){
    e.preventDefault();
    $($(this).attr('href')).slideToggle();
    $(this).find('i').toggleClass('fa-plus fa-times');

    var player = $($(this).attr('href')).find('.player-tours');
    for (var i=0; i< player.data('show'); i++) {
      player.find('tr:eq(' + i + ')').show();
    }
  });

  $('.tour-list h1').click(function(e){
    e.preventDefault();
    $(this).parent().parent().find('.group-toggle').click();
  });

  $('.tour-list .player-tours-load a').click(function(e){
    e.preventDefault();
    var player = $($(this).attr('href'));
    player.data('show', player.data('show') + 10);

    for (var i=0; i< player.data('show'); i++) {
      player.find('tr:eq(' + i + ')').show();
    }

    if (player.data('show') >= player.find('tr').length) {
      $(this).parent().hide();
    }
  });

  for (var i=0; i<$('.tour-list').data('show'); i++) {
    $('.tour-list .group:eq(' + i + ')').addClass('show');
  }

  $('.tour-list .group:eq(0) .group-toggle').click();

  $('.tour-list-action a').click(function(e){
    e.preventDefault();

    $('.tour-list').data('show', $('.tour-list').data('show') + 5);

    for (var i=0; i<$('.tour-list').data('show'); i++) {
      $('.tour-list .group:eq(' + i + ')').addClass('show');
    }

    if ($('.tour-list').data('show') >= $('.tour-list .group').length) {
      $(this).parent().hide();
    }
  });
}