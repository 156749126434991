import { isMobile } from "./common";

export default function charts() {
  $('.filters .custom-select').each(function(){
    var _instance = this;
    $(this).select2();
    $(this).on('select2:closing', function(e){
      $(_instance).parents('.filter').removeClass('active');
    });
  });

  $('.filters .filter-anchor').mousedown(function(e){
    e.preventDefault();
    e.stopPropagation();

    var _filter = $(this).parent();
    var _select = $($(this).attr('href'));

    if (_filter.hasClass('active')) {
      _select.select2('close');
    } else {
      _filter.addClass('active');
      _select.select2('open');
    }
  });

  $('.filters .filter-anchor').click(function(e){
    e.preventDefault();
  });

  $('.filters select').on('select2:select', function (e) {
    filter_charts();
  });

  $('.filter-reset').click(function(e){
    e.preventDefault();
    
    location = $('.filters').data('url');
  });

  $('.search-anchor').click(function(e){
    e.preventDefault();

    if ( $('#search-query').val() != '' ) {
      filter_charts();
    } else {
      $('.page-title').toggleClass('search-open');
      $('.page-container').toggleClass('page-search-open');
    }
  });

  $('#search-query').keypress(function (e) {
    if ( e.keyCode == 13 ) {
      filter_charts();
    }
  });

  $('#search-query').focus(function(){
    $('.page-container').addClass('page-search-open');
  });

  function filter_charts() {
    var filter_url = $('.filters').data('url');
    var params = [];

    if ( $('#search-query').val() != '' ) {
      params.push( 'chart_search=' + $('#search-query').val() );
    }

    if ( $('#filter-chart').val() != '' ) {
      params.push( 'chart_name=' + $('#filter-chart').val() );
    }

    location = filter_url + '?' + params.join('&');
  }

  $('.sortable-table').stupidtable();

  if (!isMobile()) {
    $('.sortable-table tbody td a').hover(function(){
      $('.charts-info__thumb').html('<img src="' + $(this).data('thumb') + '" />');
      var thumb = $('.charts-info__thumb img');
      var thumb_container = $('.charts-info__thumb');
      thumb.width(thumb_container.width() * 0.8);
      var thumb_height = thumb.height() > 0 ? thumb.height() : thumb.width();
  
      var left = thumb_container.offset().left + thumb.width() / 2;
      var top = $(window).height() / 2;
      
      if (thumb_container.offset().top > window.scrollY + $(window).height() / 2 - thumb_height / 2) {
        top = thumb_container.offset().top - window.scrollY + thumb_height / 2 + 40;
      }
  
      thumb.css('top', top);
      thumb.css('left', left);
      
    }, function(){
      $('.charts-info__thumb').html('');
    });
  }
  
}